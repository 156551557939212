import React, { Fragment } from 'react';
import { compose } from 'recompose';

import { withAuthorization } from '../components/Session/withAuthorization';
import { withEmailVerification } from '../components/Session/withEmailVerification';
import { UserList } from '../components/Users/UserList';
import { AuthUser } from '../types/modelsFirebase';
import { Localization } from '../localization/Localization';
import { LocalizationKey } from '../localization/LocalizationKey';

const AdminPageBase = () => (
  <Fragment>
    <h1>{Localization.getInst().localizedString(LocalizationKey.adminPageTitle)}</h1>
    <p>{Localization.getInst().localizedString(LocalizationKey.theAdminPageIsAccessibleByEverySignedInAdminUser)}</p>

    <UserList />
  </Fragment>
);

const condition = (authUser: AuthUser) => authUser && authUser.isModerator == true;

const AdminPage = compose(withEmailVerification, withAuthorization(condition))(AdminPageBase);

export default () => <AdminPage />;
