import React, { Fragment } from 'react';

import { withFirebase } from '../Firebase/withFirebase';
import { FirebaseClient } from '../Firebase/Client';
import { AdminUserRecord, OrganizationFirebase } from '../../types/modelsFirebase';
import { Locality } from '../../types/models';
import { Localization } from '../../localization/Localization';
import { LocalizationKey } from '../../localization/LocalizationKey';
import { makeStyles } from '@material-ui/styles';

//const useStyles = makeStyles(() => ({
//  tab: {
//    display: inline-block,
//    width: '150px',
//    color: 'black'
//  }
//}));

interface Props {
  firebase: FirebaseClient;
}

interface State {
  loading: boolean;
  users: AdminUserRecord[];
  ogranizations: OrganizationFirebase[];
  localities: { [key: string]: Locality[] };
}

class UserListBase extends React.Component<Props, State> {
  _initFirebase = false;
  state: State = {
    loading: false,
    users: [],
    ogranizations: [],
    localities: {}
  };

  firebaseInit = async () => {
    if (this.props.firebase && !this._initFirebase) {
      this._initFirebase = true;

      this.setState({ loading: true });
      const users = await this.getUsers();

      const ogranizationsSnapshot = await this.props.firebase.getOrganizationsSnapshot();
      const ogranizations: OrganizationFirebase[] = [];
      const localities: { [key: string]: Locality[] } = {};
      await Promise.all(
        ogranizationsSnapshot.docs.map(async (doc) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ogranizations.push({ organizationId: doc.id, ...(doc.data() as any) });
          const orgLocalities = await this.getOrganizationLocalities(doc.id);
          localities[doc.id] = orgLocalities;
        })
      );

      this.setState({
        users,
        loading: false,
        ogranizations,
        localities
      });
    }
  };

  getOrganizationLocalities = async (organizationId: string): Promise<Locality[]> => {
    const localitiesSnapshot = await this.props.firebase.getLocalitiesSnapshot(organizationId);
    const localities: Locality[] = [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    localitiesSnapshot.docs.forEach((doc) => localities.push({ localityId: doc.id, ...(doc.data() as any) }));
    return localities;
  };

  getUsers = async (): Promise<AdminUserRecord[]> => {
    const usersFunction = this.props.firebase.usersFunction();
    const usersFunctionResult = await usersFunction();
    return usersFunctionResult.data;
  };

  currentUserOranization = (user: AdminUserRecord) =>
    user.customClaims && user.customClaims.organizationId ? user.customClaims.organizationId : 'default';

  currentUserLocalities = (user: AdminUserRecord) =>
    user.customClaims && user.customClaims.localities ? user.customClaims.localities : {};

  isUserLocality = (user: AdminUserRecord, localityId: string) => !!this.currentUserLocalities(user)[localityId];

  setOrganizationClaim = async (email: string, event: React.FormEvent<HTMLSelectElement>) => {
    this.setState({ loading: true });
    const organization = event.currentTarget.value;
    const removeClaimFunction = this.props.firebase.removeClaimFunction();
    await removeClaimFunction({ email, claimName: 'localities' });
    switch (organization) {
      case 'unset':
        await removeClaimFunction({ email, claimName: 'organizationId' });
        break;
      default:
        const addOrganizationClaimFunction = this.props.firebase.addOrganizationClaimFunction();
        await addOrganizationClaimFunction({ email, organizationId: organization });
    }
    const users = await this.getUsers();
    this.setState({
      users,
      loading: false
    });
  };

  setLocalityClaim = async (user: AdminUserRecord, event: React.FormEvent<HTMLButtonElement>) => {
    this.setState({ loading: true });
    const userLocalities = { ...this.currentUserLocalities(user) };
    if (userLocalities[event.currentTarget.value]) {
      delete userLocalities[event.currentTarget.value];
    } else {
      userLocalities[event.currentTarget.value] = true;
    }
    const addLocalitiesClaimFunction = this.props.firebase.addLocalitiesClaimFunction();
    await addLocalitiesClaimFunction({ email: user.email, localities: userLocalities });
    const users = await this.getUsers();
    this.setState({
      users,
      loading: false
    });
  };

  setAllLocalitiesClaim = async (user: AdminUserRecord, localities: Locality[]) => {
    this.setState({ loading: true });

    const localitiesClaims = localities.reduce((acc, curr) => {
      const key = curr['localityId'];
      acc[key] = true;
      return acc;
    }, ({} as unknown) as { [key: string]: boolean });
    const addLocalitiesClaimFunction = this.props.firebase.addLocalitiesClaimFunction();
    await addLocalitiesClaimFunction({ email: user.email, localities: localitiesClaims });
    const users = await this.getUsers();
    this.setState({
      users,
      loading: false
    });
  };

  componentDidMount() {
    this.firebaseInit();
  }

  componentDidUpdate() {
    this.firebaseInit();
  }

  render() {
    const { users, ogranizations, localities, loading } = this.state;
    //const styles = useStyles();
    return (
      <div>
        <h2>{Localization.getInst().localizedString(LocalizationKey.users)}</h2>
        {loading && <div>{Localization.getInst().localizedString(LocalizationKey.loading)}</div>}

        <ul>
          {!loading &&
            users.map((user) => (
              <li key={user.uid}>
                <span style={{color: 'black', display: 'inline-flex', width: '300px'}}>
                  <strong>ID:</strong> {user.uid}
                </span>
                <span style={{color: 'black', display: 'inline-flex', width: '300px'}}>
                  <strong>E-Mail:</strong> {user.email}
                </span>
                {user.email && (
                  <Fragment>
                    <select
                      value={this.currentUserOranization(user)}
                      onChange={(event: React.FormEvent<HTMLSelectElement>) =>
                        user.email && this.setOrganizationClaim(user.email, event)
                      }
                    >
                      <option key="default" value="unset">
                        {Localization.getInst().localizedString(LocalizationKey.organizationSelectNone)}
                      </option>
                      {ogranizations.map((ogranization) => (
                        <option key={ogranization.organizationId} value={ogranization.organizationId}>
                          {ogranization.name}
                        </option>
                      ))}
                    </select>
                    {'default' !== this.currentUserOranization(user) &&
                      localities[this.currentUserOranization(user)].map((locality) => (
                        <button
                          onClick={(event: React.FormEvent<HTMLButtonElement>) =>
                            user.email && this.setLocalityClaim(user, event)
                          }
                          value={locality.localityId}
                          style={{ background: this.isUserLocality(user, locality.localityId) ? 'green' : 'red' }}
                          key={locality.localityId}
                        >
                          {locality.name}
                        </button>
                      ))}
                    {'default' !== this.currentUserOranization(user) && (
                      <button
                        onClick={() =>
                          user.email && this.setAllLocalitiesClaim(user, localities[this.currentUserOranization(user)])
                        }
                      >
                        {Localization.getInst().localizedString(LocalizationKey.selectAllLocalities)}
                      </button>
                    )}
                  </Fragment>
                )}
              </li>
            ))}
        </ul>
      </div>
    );
  }
}

export const UserList = withFirebase(UserListBase);
